import { Compiler, ComponentFactory, Inject, Injectable, Injector, NgModuleFactoryLoader } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { fromPromise as ObservableFromPromise } from 'rxjs/internal/observable/fromPromise';

import { DYNAMIC_COMPONENT, DYNAMIC_COMPONENT_MANIFESTS, DynamicComponentManifest } from './dynamic-component-manifest';

/**
 * Service to lazy load components.
 */
@Injectable()
export class DynamicComponentLoader {

  /**
   * Constructor of the service.
   *
   * @param manifests Manifests for the registered components.
   * @param loader ng module factory loader.
   * @param injector Dependency injector.
   * @param compiler The compiler.
   */
  constructor(
    @Inject(DYNAMIC_COMPONENT_MANIFESTS) private manifests: DynamicComponentManifest[],
    private loader: NgModuleFactoryLoader,
    private injector: Injector,
    private compiler: Compiler
  ) { }

  /**
   * Retrieves a ComponentFactory, based on the specified componentId (defined in the DynamicComponentManifest array).
   *
   * @param componentId ID of the component.
   * @param injector Dependency injector.
   */
  getComponentFactory<T>(componentId: string, injector?: Injector): Observable<ComponentFactory<T>> {
    const manifest = this.manifests.find(m => m.componentId === componentId);
    if (!manifest) {
      return throwError(`[DynamicComponentLoader] unknown componentId "${ componentId }"`);
    }
    const p = manifest.loadChildren()
      .then(module => this.compiler.compileModuleAsync(module))
      .then(ngModuleFactory => {
        const moduleRef = ngModuleFactory.create(injector || this.injector);
        const dynamicComponentType = moduleRef.injector.get(DYNAMIC_COMPONENT);
        if (!dynamicComponentType) {
          throw new Error(`[DynamicComponentLoader] Dynamic module for componentId "${ componentId }" does not contain DYNAMIC_COMPONENT as a provider.`);
        }
        return moduleRef.componentFactoryResolver.resolveComponentFactory(dynamicComponentType);
      });
    return ObservableFromPromise(p);
  }

}
