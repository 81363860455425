import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@page2flip/core';
import { Page } from '@page2flip/core/common';

import { ConfigurationHolder } from './configuration-holder.service';
import { DocumentService } from './document.service';

@Injectable()
export class ImageLoader {

  private pages: Page[];
  private numberOfPages: number;

  constructor(
    @Inject(WINDOW) private window: any,
    private config: ConfigurationHolder,
    private doc: DocumentService
  ) {
    this.pages = window[ 'data' ].pages;
    this.numberOfPages = this.pages.length;
  }

  private getPage(pageNumber: number): Page {
    let page: Page;

    this.pages.forEach(p => {
      if (p.page === pageNumber) {
        page = p;
      }
    });

    return page;
  }

  getPageImage(pageNumber: number, quality: number): string {
    if (this.doc.imageQualityLevels.includes(quality)) {
      const page: Page = this.getPage(pageNumber);

      if (page) {
        return `images/${ quality }/${ page.files[ quality ] }`;
      }
    }
  }

  getPageImageUrl(pageNumber: number, quality: number): string {
    return 'url(' + this.getPageImage(pageNumber, quality) + ')';
  }
}
