import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SpoUrlService } from './spo-url.service';
const FileSaver = require('file-saver');

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  assetId: string;
  baseUrl: string;

  constructor(private httpClient: HttpClient, spoUrlService: SpoUrlService) {
    this.assetId = spoUrlService.assetId;
    this.baseUrl = spoUrlService.backendUrl;
  }

  private concatSelectedPagesNumbers(selectedPages: number[]): string{
    let selectedNumbers = '';
    selectedPages.sort(function(a, b){return a-b}).forEach( (page, index) => {
      if(index === 0) {
        selectedNumbers += page;
      }
       else{
       selectedNumbers += ',' + page;
      }
    })
    return selectedNumbers;
  }

  navigateTo(selectedNumbers): string {
    return `${this.baseUrl}pdf/${this.assetId}/merge?pages=${this.concatSelectedPagesNumbers(selectedNumbers)}`;
  }

  download(selectedNumbers: number[]): void {
    FileSaver.saveAs(`${this.baseUrl}pdf/${this.assetId}/merge?pages=${this.concatSelectedPagesNumbers(selectedNumbers)}&download=true`, this.assetId.toString());
  }

}
