import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component, ContentChild,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Dimensions } from '@page2flip/core/common';
import { Subscription } from 'rxjs';

import { ConfigurationHolder } from '../../core/services/configuration-holder.service';
import { Constants } from '../../core/services/constants.service';
import { DocumentService, ZoomType } from '../../core/services/document.service';
import { EventListener } from '../../core/services/event-listener.service';
import { Globals } from '../../core/services/globals.service';
import { ImageLoader } from '../../core/services/image-loader.service';
import Panzoom, { PanzoomObject } from '@panzoom/panzoom';
import { DocumentComponent } from '../document/document.component';
import {PageDimensions} from "@page2flip/core/common/src/lib/interfaces/dimensions.interface";

@Component({
  selector: 'p2f-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: [ './zoom.component.css' ]
})
export class ZoomComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() pageNumbers: number[];
  @ViewChild('viewport') private viewportElementRef: ElementRef;
  @ViewChild('viewportPages') private viewportPagesElementRef: ElementRef;
  @ViewChild('viewportVisible') private viewportVisibleElementRef: ElementRef;
  @ContentChild(DocumentComponent) private documentComponent: DocumentComponent;
  @ContentChild('content') private documentRef: ElementRef;

  private element: HTMLDivElement;
  private pageDimensions: PageDimensions[];
  private visiblePages: number[];
  private readonly subscriptions: Subscription[];
  private viewportElement: HTMLDivElement;
  private viewportPagesElement: HTMLDivElement;
  private viewportVisibleElement: HTMLDivElement;
  private viewportReady = false;
  private panzoom: PanzoomObject;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private config: ConfigurationHolder,
    private doc: DocumentService,
    private elementRef: ElementRef,
    private events: EventListener,
    private globals: Globals,
    private loader: ImageLoader,
  ) {
    this.element = elementRef.nativeElement;
    this.subscriptions = [];
  }

  ngOnInit() {
    this.setupPanzoom();
    this.subscriptions.push(this.doc.pageDimensions.subscribe(dimensions => this.pageDimensions = dimensions));
    this.subscriptions.push(this.doc.visiblePages.subscribe(visiblePages => {
      this.visiblePages = visiblePages;
      this.resetViewport();
    }));
  }

  private setupPanzoom() {
    const p2fContent = document.getElementById('p2f-content');
    this.panzoom = Panzoom(p2fContent, {
      minScale: 1,
      maxScale: this.config.touchMode ?
        <number> Constants.zoomFactorsTouch[ Constants.zoomFactorsTouch.length - 1 ] :
        <number> Constants.zoomFactorsNonTouch[ Constants.zoomFactorsNonTouch.length - 1 ],
      animate: true,
      panOnlyWhenZoomed: true,
      contain: 'outside',
      cursor: 'drag',
      roundPixels: true,
      handleStartEvent: event => {
        if (this.panzoom.getScale() > 1.001 && this.config.touchMode) {
          event.preventDefault();
        }
      }
    });
    p2fContent.addEventListener('panzoomchange', () => {
      //Doc zoom update isn't done here in order to keep this as small as possible
      if (!this.viewportReady) {
        this.setViewport();
      }
      this.setVisible();
    });
    this.doc.zoomChangeFromToolbar.subscribe(zoom => this.zoomFromToolbar(zoom));
  }

  ngAfterViewInit() {
    this.viewportElement = this.viewportElementRef.nativeElement;
    this.viewportPagesElement = this.viewportPagesElementRef.nativeElement;
    this.viewportVisibleElement = this.viewportVisibleElementRef.nativeElement;
    this.documentComponent.pagesElementRef.nativeElement.addEventListener('wheel', (event: WheelEvent) => {
      const oldZoom = this.panzoom.getScale();
      this.panzoom.zoomWithWheel(event);
      if (oldZoom !== this.panzoom.getScale()) {
        this.doc.setCurrentZoomFactor(this.panzoom.getScale());
      }
    });
    if (this.config.touchMode) {
      document.getElementById('p2f-content').addEventListener('panzoomstart', (event) => this.onPan(event));
      document.getElementById('p2f-content').addEventListener('panzoomchange', (event) => this.onPan(event));
      document.getElementById('p2f-content').addEventListener('panzoomend', (event) => this.onPan(event));
    }
  }

  private onPan(event) {
    this.doc.setCurrentZoomFactor(event.detail.scale);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private zoomFromToolbar(zoom: ZoomType) {
    switch (zoom) {
      case 'reset':
        this.panzoom.reset();
        break;
      case 'out':
        this.panzoom.zoomOut();
        this.setVisible();
        break;
      case 'in':
        this.panzoom.zoomIn();
        this.setVisible();
    }
    this.doc.setCurrentZoomFactor(this.panzoom.getScale());
  }

  private resetViewport() {
    const childrenToDelete: Element[] = [];
    if (this.viewportPagesElement) {
      for (let i = 0; i < this.viewportPagesElement.children.length; i++) {
        const child = this.viewportPagesElement.children[i];
        if (!(child.id === 'visible')) {
          childrenToDelete.push(child);
        }
      }
      childrenToDelete.forEach(child => child.remove());
    }
    this.viewportReady = false;
  }

  @HostListener('window:keydown.ArrowUp')
  @HostListener('window:keydown.ArrowUp')
  onMoveUp() {
    const pan = this.panzoom.getPan();
    this.panzoom.pan(pan.x, pan.y  + Constants.zoomMovementDistance);
  }

  @HostListener('window:keydown.ArrowRight')
  onMoveRight() {
    const pan = this.panzoom.getPan();
    this.panzoom.pan(pan.x - Constants.zoomMovementDistance, pan.y);
  }

  @HostListener('window:keydown.ArrowDown')
  onMoveDown() {
    const pan = this.panzoom.getPan();
    this.panzoom.pan(pan.x, pan.y  - Constants.zoomMovementDistance);
  }

  @HostListener('window:keydown.ArrowLeft')
  onMoveLeft() {
    const pan = this.panzoom.getPan();
    this.panzoom.pan(pan.x + Constants.zoomMovementDistance, pan.y);
  }

  @HostListener('window:keydown.Control.ArrowUp')
  @HostListener('window:keydown.Meta.ArrowUp')
  onJumpTop() {
    this.panzoom.pan(this.panzoom.getPan().x, this.element.clientHeight, { animate: true, duration: 500 });
  }

  @HostListener('window:keydown.Control.ArrowRight')
  @HostListener('window:keydown.Meta.ArrowRight')
  onJumpRight() {
    this.panzoom.pan(-this.element.clientWidth, this.panzoom.getPan().y, { animate: true, duration: 500 });
  }

  @HostListener('window:keydown.Control.ArrowDown')
  @HostListener('window:keydown.Meta.ArrowDown')
  onJumpBottom() {
    this.panzoom.pan(this.panzoom.getPan().x, -this.element.clientHeight, { animate: true, duration: 500 });
  }

  @HostListener('window:keydown.Control.ArrowLeft')
  @HostListener('window:keydown.Meta.ArrowLeft')
  onJumpLeft() {
    this.panzoom.pan(this.element.clientWidth, this.panzoom.getPan().y, { animate: true, duration: 500 });
  }

  private setViewport() {
    const height: number = Constants.zoomViewportPreviewHeight;
    const width: number = (this.doc.scaledWidth() / this.doc.scaledHeight()) * height;
    this.viewportElement.style.width = width + 'px';
    this.viewportElement.style.height = height + 'px';

    this.config.layout.menuPosition === 'left' || this.config.touchMode ?
      this.viewportElement.style.right = '20px' :
      this.viewportElement.style.left = '20px';

    this.config.layout.toolbarPosition === 'top' || this.config.touchMode ?
      this.viewportElement.style.bottom = '20px' :
      this.viewportElement.style.top = '20px';

    this.documentComponent.visiblePages.forEach(pageNumber => {
      const image: HTMLImageElement = this.document.createElement('img');
      image.height = Constants.zoomViewportPreviewHeight;
      image.src = this.loader.getPageImage(pageNumber, 250);
      this.viewportPagesElement.appendChild(image);
    });

    this.viewportReady = true;
  }

  private setVisible() {
    const panzoomScale = this.panzoom.getScale();
    //scale will never be 1 because of the floating point, but very close to 1
    if (panzoomScale > 1.001) {
      const elem = document.getElementById('p2f-content');
      const scale: number = Constants.zoomViewportPreviewHeight / this.doc.scaledHeight();
      const width: number = elem.clientWidth / panzoomScale;
      const height: number = elem.clientHeight / panzoomScale;
      this.viewportVisibleElement.style.width = scale * width + 'px';
      this.viewportVisibleElement.style.height = scale * height + 'px';
      this.viewportVisibleElement.style.transformOrigin = `center`;
      this.viewportVisibleElement.style.transform = `translate(${scale * (-1) * this.panzoom.getPan().x}px, ${scale * (-1) * this.panzoom.getPan().y}px)`;
      this.viewportElement.style.visibility = 'visible';
    } else {
      this.viewportElement.style.visibility = 'hidden';
    }
  }

}
