import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone, Renderer2 } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WINDOW } from '@page2flip/core';
import { DialogType, PanelType, VisibilityState } from '@page2flip/core/common';
import { Observable, Subject } from 'rxjs';

import { BootController } from '../../../boot';
import { environment } from '../../../environments/environment';
import { keys } from '../../app.keys';
import { SidePanelService } from '../../base/side-panel/side-panel.service';
import { ConfigurationHolder } from './configuration-holder.service';
import { Constants } from './constants.service';
import { DocumentService } from './document.service';
import { FeatureLoader } from './feature-loader.service';
import { Globals } from './globals.service';

/**
 * Service to control events.
 */
@Injectable()
export class EventListener{

  private _renderer: Renderer2;
  private _hammer: HammerManager;

  private documentElement: HTMLDivElement;
  private viewerElement: HTMLDivElement;

  private touchIntroSubject: Subject<void>;
  private touchMenuSubject: Subject<void>;

  private _toolbarVisibility: Subject<VisibilityState> = new Subject<VisibilityState>();

  toolbarVisibility(): Observable<VisibilityState> {
    return this._toolbarVisibility.asObservable();
  }

  constructor(@Inject(DOCUMENT) private document: any,
              @Inject(WINDOW) private window: any,
              private config: ConfigurationHolder,
              private dialog: MatDialog,
              private doc: DocumentService,
              private feature: FeatureLoader,
              private globals: Globals,
              private sidePanel: SidePanelService,
              private zone: NgZone) {
    this.touchIntroSubject = new Subject<void>();
    this.touchMenuSubject = new Subject<void>();
  }

  get renderer(): Renderer2 {
    return this._renderer;
  }

  set renderer(renderer: Renderer2) {
    this._renderer = renderer;
    this._hammer = new Hammer(this.document.body);
  }

  get touchIntro(): Observable<void> {
    return this.touchIntroSubject.asObservable();
  }

  get touchMenu(): Observable<void> {
    return this.touchMenuSubject.asObservable();
  }

  enableVerticalSwipe() {
    if (this._hammer) {
      this._hammer.get('swipe').set({enable: true, direction: Hammer.DIRECTION_VERTICAL});
    }
  }

  disableVerticalSwipe() {
    if (this._hammer) {
      this._hammer.get('swipe').set({enable: false});
    }
  }

  listen() {

    this.viewerElement = this.document.querySelector('p2f-viewer');
    this.documentElement = this.document.querySelector('p2f-document');

    /// creator data ///////////////////////////////////////////////////////////////

    const messageListener = this.renderer.listen('window', 'message', (event: MessageEvent) => {
      if (event.data.creator) {
        if (event.data.creator.config) this.window.sessionStorage.setItem('config', event.data.creator.config);
        if (event.data.creator.hotspots) this.window.sessionStorage.setItem('hotspots', event.data.creator.hotspots);

        this.zone.runOutsideAngular(() => BootController.instance.restart());

        parent.postMessage({
          viewer: {
            loaded: true
          }
        }, event.origin);

        messageListener(); // remove event listener
      }
    });

    /// touch menu /////////////////////////////////////////////////////////////////

    this._hammer.on('swipedown', (event: TouchEvent | any) => {
      if (!event.target.classList.contains('touch-intro') && this.doc.currentZoomFactor < 1.001) {
        this.touchMenuSubject.next();
      }
    });

    /// toolbar ////////////////////////////////////////////////////////////////////

    this.renderer.listen('window', 'mousemove', (event: MouseEvent) => {
      if (this.doc.currentZoomFactor < 1.001 &&
        this.globals.menuVisibility === VisibilityState.Hidden &&
        !this.sidePanel.isVisible() &&
        !this.dialog.openDialogs.length) {
        this.config.layout.toolbarPosition === 'top' ?
          event.clientY < 100 ?
            this._toolbarVisibility.next(VisibilityState.Visible) :
            this._toolbarVisibility.next(VisibilityState.Hidden) :
          event.clientY > this.viewerElement.clientHeight - 100 ?
            this._toolbarVisibility.next(VisibilityState.Visible) :
            this._toolbarVisibility.next(VisibilityState.Hidden);
      }
    });

    /// navigation /////////////////////////////////////////////////////////////////

    this.renderer.listen('window', 'keydown.' + keys.navigation.pageBack, () => {
      this.doc.resetZoom();
      this.doc.pageBack();
    });

    this.renderer.listen('window', 'keydown.' + keys.navigation.firstPage, () => {
      this.doc.resetZoom();
      this.doc.firstPage();
    });

    this.renderer.listen('window', 'keydown.' + keys.navigation.lastPage, () => {
      this.doc.resetZoom();
      this.doc.lastPage();
    });

    this.renderer.listen('window', 'keydown.' + keys.navigation.previousPage, () => {
      if (this.doc.currentZoomFactor < 1.001) {
        this.doc.previousPage();
      }
    });

    this.renderer.listen('window', 'keydown.' + keys.navigation.nextPage, () => {
      if (this.doc.currentZoomFactor < 1.001) {
        this.doc.nextPage();
      }
    });

    this.renderer.listen(this.documentElement, 'swiperight', () => {
      if (this.doc.currentZoomFactor < 1.001) {
        this.doc.previousPage();
      }
    });

    this.renderer.listen(this.documentElement, 'swipeleft', () => {
      if (this.doc.currentZoomFactor < 1.001) {
        this.doc.nextPage();
      }
    });

    /// zoom ///////////////////////////////////////////////////////////////////////

    this.renderer.listen('window', 'keydown.' + keys.zoom.zoomIn, () => {
      this.doc.zoomIn();
    });

    this.renderer.listen('window', 'keydown.' + keys.zoom.zoomOut, () => {
      this.doc.zoomOut();
    });

    this.renderer.listen('window', 'keydown.' + keys.zoom.resetZoom, () => {
      this.doc.resetZoom();
    });

    this.renderer.listen(this.documentElement, 'tap', (event: TouchEvent) => this.toggleZoom(event));

    /// panels & dialogs ///////////////////////////////////////////////////////////

    Object.keys(keys.panels).forEach((feature: PanelType) => {
      this.renderer.listen('window', 'keydown.' + keys.panels[feature], () => {
        if (this.config.features[feature]) {
          this.feature.load(feature);
          // TODO: add GA event
        }
      });
    });

    Object.keys(keys.dialogs).forEach((feature: DialogType) => {
      this.renderer.listen('window', 'keydown.' + keys.dialogs[feature], () => {
        if (this.config.features[feature] ||
          feature === 'legal' && (this.config.legal.imprint || this.config.legal.termsOfService || this.config.legal.privacyPolicy) ||
          feature === 'config' && (!environment.production || this.config.debugMode)) {
          const options: any = feature === 'download' ? 'all' : null;
          this.feature.load(feature, options);
          // TODO: add GA event
        }
      });
    });

    this.renderer.listen('window', 'keydown.' + keys.close, () => {
      this.sidePanel.hide();
      this.dialog.closeAll();
    });

  }

  toggleZoom(event: TouchEvent | any) {
    event.preventDefault();
    if (event.tapCount === 2) {
      this.globals.doubleTapPosition = {
        x: event.srcEvent.offsetX / this.doc.currentZoomFactor,
        y: event.srcEvent.offsetY / this.doc.currentZoomFactor
      };

      const maxZoomFactor: number = this.config.touchMode ?
        <number>Constants.zoomFactorsTouch[Constants.zoomFactorsTouch.length - 1] :
        <number>Constants.zoomFactorsNonTouch[Constants.zoomFactorsNonTouch.length - 1];

      this.doc.currentZoomFactor > (maxZoomFactor - 0.001) ?
        this.doc.resetZoom() :
        this.doc.zoomIn();
    }
  }

  showTouchIntro() {
    this.touchIntroSubject.next();
  }

}
