import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

import { ConfigurationHolder } from './configuration-holder.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare global {
  interface StyleSheet {
    cssRules: any;
    insertRule: any;
  }
}

/**
 * Service to control the design of the viewer.
 */
@Injectable()
export class Designer {
  private sheet: StyleSheet;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private config: ConfigurationHolder,
    private sanitizer: DomSanitizer
  ) {
    this.sheet = (() => {
      const style: HTMLStyleElement = this.document.createElement('style');
      style.appendChild(this.document.createTextNode(''));
      this.document.head.appendChild(style);
      return style.sheet;
    })();

    this.setColors();
    this.setTransparency();
    this.setBackground();
  }

  private setColors() {
    /// primary background color ///////////////////////////////////////////////////

    this.insertRule(
      [
        'body',
        'p2f-browser-dimensions',
        'p2f-browser-error',
        '.color-picker',
        '.count',
        '.header-line span',
        '.mat-dialog-container',
        '.mat-raised-button',
        '.mat-select-content'
      ],
      'background-color',
      this.config.design.colors.primaryBackground + ' !important'
    );

    /// secondary background color /////////////////////////////////////////////////

    this.insertRule(
      [
        'p2f-side-panel',
        '.close',
        '.flyout',
        '.mat-menu-panel',
        '.mat-snack-bar-container',
        '.mat-toolbar',
        '.mat-tooltip',
        '.page-marker',
        '.tooltip',
        '.touch-intro',
        '.touch-menu'
      ],
      'background-color',
      this.config.design.colors.secondaryBackground
    );

    this.insertRule(
      ['.details', '.item-wrapper', '.matches'],
      'background-color',
      this.hexToRgba(this.config.design.colors.secondaryBackground, 0.75)
    );

    this.insertRule(
      ['.viewport-visible'],
      'background-color',
      this.hexToRgba(this.config.design.colors.secondaryBackground, 0.5)
    );
    this.insertRule(
      ['.viewport-visible'],
      'border-color',
      this.hexToRgba(this.config.design.colors.secondaryBackground, 0.55)
    );

    /// primary foreground color ///////////////////////////////////////////////////

    this.insertRule(
      [
        'input:not(.color)',
        'p2f-side-panel',
        '.browser-dimensions',
        '.browser-error',
        '.clickable.active',
        '.clickable:hover',
        '.close',
        '.color-picker *',
        '.flyout',
        '.mat-dialog-container',
        '.mat-menu-item',
        '.mat-menu-item .mat-icon',
        'mat-icon.previous',
        'mat-icon.next',
        '.mat-option',
        '.mat-raised-button',
        '.mat-select-arrow',
        '.mat-select-value',
        '.mat-snack-bar-container',
        '.mat-toolbar',
        '.mat-tooltip',
        '.page-marker',
        '.tooltip',
        '.touch-intro',
        '.touch-menu'
      ],
      'color',
      this.config.design.colors.primaryForeground + ' !important'
    );

    this.insertRule(
      ['.bullet.active'],
      'background-color',
      this.config.design.colors.primaryForeground + ' !important'
    );

    this.insertRule(
      ['.bullet.active', '.color-picker .cursor'],
      'border-color',
      this.config.design.colors.primaryForeground + ' !important'
    );

    this.insertRule(
      [
        'p2f-table-of-contents .parent:hover .title',
        '.clickable',
        '.pay:hover'
      ],
      'color',
      this.hexToRgba(this.config.design.colors.primaryForeground, 0.65) +
        ' !important'
    );

    this.insertRule(
      ['.mat-input-element:disabled'],
      'color',
      this.hexToRgba(this.config.design.colors.primaryForeground, 0.5) +
        ' !important'
    );

    this.insertRule(
      [
        '.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar'
      ],
      'background-color',
      this.hexToRgba(this.config.design.colors.primaryForeground, 0.75)
    );
    this.insertRule(
      [
        '.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb'
      ],
      'background-color',
      this.hexToRgba(this.config.design.colors.primaryForeground, 1)
    );

    /// secondary foreground color /////////////////////////////////////////////////

    this.insertRule(
      [
        'a',
        '.active',
        '.article-id',
        '.count',
        '.info',
        '.mat-form-field-label',
        '.quantity',
        '.stats',
        '.vat',
        '.version'
      ],
      'color',
      this.config.design.colors.secondaryForeground + ' !important'
    );

    this.insertRule(
      ['a:hover'],
      'color',
      this.hexToRgba(this.config.design.colors.secondaryForeground, 0.65) +
        ' !important'
    );

    this.insertRule(
      [
        '.divider',
        '.mat-checkbox-checked .mat-checkbox-background',
        '.mat-radio-inner-circle'
      ],
      'background-color',
      this.config.design.colors.secondaryForeground + ' !important'
    );

    this.insertRule(
      [
        '.bullet',
        '.color',
        '.color-picker',
        '.color-picker input',
        '.color-picker .selected-color',
        '.color-preset > div',
        '.mat-checkbox-frame',
        '.mat-radio-outer-circle'
      ],
      'border-color',
      this.config.design.colors.secondaryForeground + ' !important'
    );

    this.insertRule(
      ['.color-picker .arrow-right'],
      'border-right-color',
      this.config.design.colors.secondaryForeground + ' !important'
    );

    this.insertRule(
      ['.mat-slide-toggle-bar'],
      'background-color',
      this.hexToRgba(this.config.design.colors.secondaryForeground, 0.75)
    );
    this.insertRule(
      ['.mat-slide-toggle-thumb'],
      'background-color',
      this.hexToRgba(this.config.design.colors.secondaryForeground, 1)
    );
    this.insertRule(
      ['.mat-disabled .mat-slide-toggle-bar'],
      'background-color',
      this.hexToRgba(this.config.design.colors.secondaryForeground, 0.15)
    );
    this.insertRule(
      ['.mat-disabled .mat-slide-toggle-thumb'],
      'background-color',
      this.hexToRgba(this.config.design.colors.secondaryForeground, 0.2)
    );
  }

  private setTransparency() {
    if (this.config.design.transparency) {
      this.insertRule(
        ['.mat-tooltip', '.tooltip', '.transparent'],
        'background-color',
        this.hexToRgba(this.config.design.colors.secondaryBackground, 0.85) +
          ' !important'
      );
    }
  }

  private setBackground() {
    if (this.config.design.background) {
      this.insertRule(
        ['body'],
        'background',
        `${this.config.design.colors.primaryBackground} url('images/background.png')`
      );

      if (this.config.isFirefox) {
        this.insertRule(
          ['body'],
          '-moz-background-size',
          this.config.design.backgroundSize + ' !important'
        );
      } else {
        this.insertRule(
          ['body'],
          'background-size',
          this.config.design.backgroundSize + ' !important'
        );
      }
    }
  }

  private insertRule(selectors: string[], property: string, value: string) {
    selectors.forEach(selector =>
      this.sheet.insertRule(
        `${selector} { ${property}: ${value}; }`,
        this.sheet.cssRules.length
      )
    );
  }

  hexToRgba(hex: string, alpha: number): string {
    // expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    hex = hex.replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, red, green, blue) => {
        return red + red + green + green + blue + blue;
      }
    );

    // split full form into [rr, gg, bb] array (e.g. ["00", "33", "FF"])
    const rgb: RegExpExecArray = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
      hex
    );

    // convert hexadecimal rr, gg, bb parts into integers (e.g. 0, 51, 255)
    const r: number = parseInt(rgb[1], 16);
    const g: number = parseInt(rgb[2], 16);
    const b: number = parseInt(rgb[3], 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
}
