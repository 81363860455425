import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {DataService} from "@page2flip/core";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private httpClient: HttpClient) {
  }

  decreaseHotspotQuantity(apiUrl: string, assetId: string, hotspotId: string): Observable<any> {
    const url = `${apiUrl}/p2f/article/sold`;
    return this.httpClient.post(url, {assetId: assetId, hotspotId: hotspotId});
  }

  changeArticleState(apiUrl: string, assetId: string, hotspotId: string, state: string) {
    const url = `${apiUrl}/p2f/article/state`;
    return this.httpClient.post(url, {assetId: assetId, hotspotId: hotspotId, state: state});
  }

}
