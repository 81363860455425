import {
  ANALYZE_FOR_ENTRY_COMPONENTS,
  ModuleWithProviders,
  NgModule,
  NgModuleFactoryLoader,
  SystemJsNgModuleLoader,
  Type,
} from '@angular/core';
import { ROUTES } from '@angular/router';

import { DynamicComponentLoader } from './dynamic-component-loader.service';
import { DYNAMIC_COMPONENT, DYNAMIC_COMPONENT_MANIFESTS, DynamicComponentManifest } from './dynamic-component-manifest';

@NgModule({})
export class DynamicComponentLoaderModule {

  static forRoot(manifests: DynamicComponentManifest[]): ModuleWithProviders<DynamicComponentLoaderModule> {
    return {
      ngModule: DynamicComponentLoaderModule,
      providers: [
        DynamicComponentLoader,
        { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
        { provide: ROUTES, useValue: manifests, multi: true },        // provider for Angular CLI to analyze
        { provide: DYNAMIC_COMPONENT_MANIFESTS, useValue: manifests } // provider for DynamicComponentLoader to analyze
      ]
    };
  }

  static forChild(component: Type<any>): ModuleWithProviders<DynamicComponentLoaderModule> {
    return {
      ngModule: DynamicComponentLoaderModule,
      providers: [
        { provide: ANALYZE_FOR_ENTRY_COMPONENTS, useValue: component, multi: true },
        { provide: ROUTES, useValue: [], multi: true },     // provider for @angular/router to parse
        { provide: DYNAMIC_COMPONENT, useValue: component } // provider for DynamicComponentLoader to analyze
      ]
    };
  }

}
