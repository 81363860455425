import { Injectable } from '@angular/core';

import { Position, VisibilityState } from '@page2flip/core/common';

/**
 * Service to store global application variables.
 */
@Injectable()
export class Globals {

  /** Menu visibility state. */
  menuVisibility: VisibilityState = VisibilityState.Hidden;

  /** Double tap position on touch devices. */
  doubleTapPosition: Position;

}
