import { BehaviorSubject, Observable } from 'rxjs';


export class BootController {

  private static _instance: BootController;
  private rebootSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);

  static get instance() {
    if (!BootController._instance) {
      BootController._instance = new BootController();
    }

    return BootController._instance;
  }

  get reboot(): Observable<boolean> {
    return this.rebootSubject.asObservable();
  }

  restart() {
    this.rebootSubject.next(true);
  }

}
