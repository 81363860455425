import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@page2flip/core';
import { ExpandState, FeatureData, VisibilityState } from '@page2flip/core/common';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

/**
 * Service to control the side panel behavior.
 */
@Injectable()
export class SidePanelService {

  /** Subject that controls the data passed to the side panel. */
  private readonly _data: ReplaySubject<FeatureData> = new ReplaySubject<FeatureData>();

  /** Subject that controls the visibility of the side panel. */
  private readonly _visibility: BehaviorSubject<VisibilityState> = new BehaviorSubject<VisibilityState>(VisibilityState.Hidden);

  /** Subject that controls the width of the side panel. */
  private readonly _expand: BehaviorSubject<ExpandState> = new BehaviorSubject<ExpandState>(this.window.JSON.parse(this.window.sessionStorage.getItem('sidePanelExpanded')) ? ExpandState.Expanded : ExpandState.Collapsed);

  /**
   * Constructor of the service.
   *
   * @param window Token for the Window object.
   */
  constructor(@Inject(WINDOW) private window: any) { }

  /**
   * Shows the side panel and passes the feature component to it.
   *
   * @param data Data passed to the side panel.
   */
  show(data?: FeatureData): BehaviorSubject<VisibilityState> {
    this._visibility.next(VisibilityState.Visible);
    this._data.next(data);
    return this._visibility;
  }

  /**
   * Hides the side panel.
   */
  hide() {
    this._visibility.next(VisibilityState.Hidden);
  }

  /**
   * Expands the side panel.
   */
  expand() {
    this._expand.next(ExpandState.Expanded);
    this.window.sessionStorage.setItem('sidePanelExpanded', this.window.JSON.stringify(true));
  }

  /**
   * Collapses the side panel.
   */
  collapse() {
    this._expand.next(ExpandState.Collapsed);
    this.window.sessionStorage.setItem('sidePanelExpanded', this.window.JSON.stringify(false));
  }

  /**
   * Gets an observable that is notified when the side panel data has changed.
   */
  data(): Observable<FeatureData> {
    return this._data.asObservable();
  }

  /**
   * Gets an observable that is notified when the side panel visibility has changed.
   */
  visibility(): Observable<VisibilityState> {
    return this._visibility.asObservable();
  }

  /**
   * Gets an observable that is notified when the side panel width has changed.
   */
  width(): Observable<ExpandState> {
    return this._expand.asObservable();
  }

  /**
   * Whether or not the side panel is visible.
   */
  isVisible(): boolean {
    return this._visibility.getValue() === VisibilityState.Visible;
  }

  /**
   * Whether or not the side panel is expanded.
   */
  isExpanded(): boolean {
    return this._expand.getValue() === ExpandState.Expanded;
  }

}
