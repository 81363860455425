import { InjectionToken } from '@angular/core';

/** Token to locate the correct component factory. */
export const DYNAMIC_COMPONENT = new InjectionToken<any>('DYNAMIC_COMPONENT');

/** Token to allow the application to read the manifests. */
export const DYNAMIC_COMPONENT_MANIFESTS = new InjectionToken<any>('DYNAMIC_COMPONENT_MANIFESTS');

/**
 * Manifest interface which aids in the consumption of dynamic components.
 */
export interface DynamicComponentManifest {

  /** Unique identifier, used in the application to retrieve a ComponentFactory. */
  componentId: string;

  /** Unique identifier, used internally by Angular. */
  path: string;

  /** Path to component module. */
  loadChildren: any;
}
