<div #shadow class="page-bg mat-elevation-z8"></div>

<div id='pages' #pages class="pages">
  <div *ngFor="let pageNumber of pageNumbers"
       class="page"
       [attr.page]="pageNumber"></div>
</div>

<div id="page-marker-animation" *ngIf="config.options.pageMarkersDefault === false && !isMobile && !isViewerInIframe"
style="position: absolute; z-index: 9999999">
  <div *ngFor="let pageMarker of pageMarkers; index as i"
       class="page-marker-wrapper">
    <div class="page-marker absolute"
         [id]="'pm' + i"
         [class.transparent]="transparency && !pageMarker.tabColor"
         [style.backgroundColor]="setPageMarkerBackground(pageMarker.tabColor)"
         (click)="gotoPage(pageMarker.page)">
      <div class="page-marker-shadow mat-elevation-z4"></div>
      <span class="page-marker-label ml-20" [style.color]="pageMarker.tabTextColor">{{pageMarker.tabText}}</span>
    </div>
  </div>
</div>
