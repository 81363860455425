import { Injectable } from '@angular/core';

/**
 * Service to store application constants.
 */
@Injectable()
export class Constants {

  /** Hover/hidden hotspot animation duration in ms. */
  static hotspotAnimationDuration = 1000;

  /** Maximum hotspot dialog dimensions (width or height) in %. */
  static hotspotDialogPercentage = 75;

  /** Distance between mouse cursor and hotspot tooltip in px. */
  static hotspotTooltipDistance = 15;

  /** Aspect ratio for video hotspots opened in hotspot dialog. */
  static hotspotVideoRatio: number = 16 / 9;

  /** Image loader chunk size. */
  static imageLoaderChunkSize = 10;

  /** Page flip animation duration in ms. */
  static pageFlipAnimationDuration = 600;

  /** Page preview height in px. */
  static pagePreviewHeight = 250;

  /** Pinch event threshold in ms. */
  static pinchEventThreshold = 150;

  /** Resize event threshold in ms. */
  static resizeEventThreshold = 250;

  /** Snack-bar duration in ms. */
  static snackBarDuration = 1500;

  /** Zoom factors for non-touch devices. */
  static zoomFactorsNonTouch: number[] = [1, 1.5, 2, 2.5, 3];

  /** Zoom factors for touch devices. */
  static zoomFactorsTouch: number[] = [1, 2];

  /** How much the wheel should zoom in. */
  static wheelZoom = 0.1;

  /** Zoom viewport movement distance in px. */
  static zoomMovementDistance = 50;

  /** Zoom viewport preview height in px. */
  static zoomViewportPreviewHeight = 150;

}
