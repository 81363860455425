import { Injectable } from '@angular/core';
import { MatomoTracker } from '@ngx-matomo/tracker';
import { MatomoEvent } from "@page2flip/core/common/src/lib/interfaces/matomo-event.interface";

/**
 * Service to send Matomo events.
 */
@Injectable()
export class MatomoService{

  constructor(private readonly tracker: MatomoTracker) {
  }

  sendEvent(event: MatomoEvent, documentId: string) {
    this.tracker.trackEvent(event.category, event.action, this.getEventName(event, documentId));
  }

  private getEventName(event: MatomoEvent, documentId: string) {
    let eventName = documentId + ": ";
    if (event.custom1) {
      eventName += event.custom1
    }
    if (event.custom2) {
      eventName += ', ' + event.custom2;
    }
    if (event.custom3) {
      eventName += ', ' + event.custom3;
    }
    return eventName;
  }

}
