import {Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FeatureData, LoaderService} from '@page2flip/core/common';
import {environment} from "../../../environments/environment";

/**
 * Component that represents dialogs inside the viewer.
 */
@Component({
  selector: 'p2f-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.css']
})
export class DialogComponent implements OnInit, OnDestroy {

  /** Reference to the content container of the dialog. */
  @ViewChild('content', {read: ViewContainerRef, static: true}) private contentContainer: ViewContainerRef;
  apiUrl = environment.apiUrl;

  /**
   * Constructor of the component.
   *
   * @param data      Data passed to the dialog instance.
   * @param dialogRef Reference to the dialog instance.
   * @param loader    Service to control the loader component.
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: FeatureData,
              public dialogRef: MatDialogRef<DialogComponent>,
              private loader: LoaderService) {
  }

  /**
   * Creates an instance of the feature component inside the content container of the dialog.
   */
  ngOnInit() {
    if (this.data.componentFactory) {
      this.contentContainer.createComponent(this.data.componentFactory);
      this.loader.stop();
    }
  }

  /**
   * Removes the feature component from the content container of the dialog.
   */
  ngOnDestroy() {
    this.contentContainer.clear();
  }

}
