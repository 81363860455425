import { NgModule } from '@angular/core';

import { ConfigurationHolder } from './services/configuration-holder.service';
import { Constants } from './services/constants.service';
import { Designer } from './services/designer.service';
import { DocumentService } from './services/document.service';
import { EventListener } from './services/event-listener.service';
import { FeatureLoader } from './services/feature-loader.service';
import { Globals } from './services/globals.service';
import { GoogleAnalytics } from './services/google-analytics.service';
import { ImageLoader } from './services/image-loader.service';
import { Storage } from './services/storage.service';
import { PdfService } from './services/pdf.service';
import { SpoUrlService } from './services/spo-url.service';
import { MatomoService } from "./services/matomo.service";
import { NgxMatomoTrackerModule } from "@ngx-matomo/tracker";
import { matomoConfig } from "../../config/window-config";
import {
  CookieConsentDialogComponent
} from "@page2flip/core/common/src/lib/components/cookie-consent-dialog/cookie-consent-dialog.component";
import {CoreCommonModule} from "@page2flip/core/common";

@NgModule({
  providers: [
    ConfigurationHolder,
    Constants,
    Designer,
    DocumentService,
    EventListener,
    FeatureLoader,
    Globals,
    GoogleAnalytics,
    MatomoService,
    ImageLoader,
    Storage,
    PdfService,
    SpoUrlService
  ],
  imports: [
    CoreCommonModule,
    NgxMatomoTrackerModule.forRoot({// This will fail but the angular AOT compiler won't let us import it dynamically
      siteId: matomoConfig?.ANALYTICS_ID ?? '',
      trackerUrl: matomoConfig?.MATOMO_URL ?? '',
    }),
  ],
  entryComponents: [ CookieConsentDialogComponent ]
})
export class CoreModule{
}
